import { useMutation } from "@apollo/client";
import {
  ABCDLicensesAPFOptions,
  ABCDLicensesCSPAOptions,
  ABCDLicensesOptions,
} from "components/constants";
import { Formik } from "formik";
import { ADD_STUDENT_ORG_CREDENTIAL } from "graphql/ADD_STUDENT_ORG_CREDENTIAL";
import { useRootStore } from "hooks";
import { useGetUserIsStudent } from "modules/common/hooks/useGetCurrentUserType";
import moment from "moment";
import React, { useMemo } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Form, Grid } from "tabler-react";

type Props = {
  orgId: number;
  orgName: string;
  studentId: number;
  toggle?: () => void;
};

const AddLicenseForm: React.FC<Props> = ({
  orgId,
  orgName,
  studentId,
  toggle,
}) => {
  const isStudent = useGetUserIsStudent();
  const { currentUser } = useRootStore();
  const [addStudentOrgCredential] = useMutation(ADD_STUDENT_ORG_CREDENTIAL, {
    refetchQueries: ["GET_ORG_CREDENTIALS", "GET_USER_REGISTRATIONS_BY_IDS"],
    onCompleted: () => {
      toast.success("Credential added successfully");
    },
  });

  const licenseList = useMemo(() => {
    let selectedList = ABCDLicensesOptions;
    if (orgId === 2) selectedList = ABCDLicensesCSPAOptions;
    if (orgId === 3) selectedList = ABCDLicensesAPFOptions;

    return selectedList.map((option) => {
      return {
        value: option,
        label: option,
      };
    });
  }, [orgId]);

  const renderForm = ({
    handleChange,
    setFieldValue,
    handleSubmit,
    values,
    dirty,
  }) => {
    return (
      <>
        <Grid.Row>
          <Grid.Col>
            <Form.Group label={orgName}></Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <Form.Group label="">
              <Select
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
                name="license"
                placeholder="Select license..."
                searchText={false}
                options={licenseList}
                onChange={(e) => {
                  setFieldValue("license", e.value);
                }}
                value={
                  licenseList.find((i) => i.value === values.license) || null
                }
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col sm={6} md={6}>
            <Form.Group label="">
              <Form.Input
                name="license_number"
                placeholder=""
                value={values?.license_number}
                onChange={handleChange}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Button
              color="gray-dark"
              pill
              size="sm"
              className="float-right"
              onClick={handleSubmit}
            >
              SAVE
            </Button>
          </Grid.Col>
        </Grid.Row>
      </>
    );
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        org_id: orgId,
        student_id: studentId,
        verified_on: null,
        verified_by: null,
        license: "",
        license_number: "",
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (!isStudent) {
          values.verified_by = currentUser.id.toString();
          values.verified_on = moment().format("YYYY-MM-DD");
        }
        addStudentOrgCredential({
          variables: {
            addOrgCredentialInput: {
              ...values,
            },
          },
        });

        resetForm();
        setSubmitting(false);
        if (typeof toggle === "function") {
          toggle();
        }
      }}
    >
      {(formData) => renderForm(formData)}
    </Formik>
  );
};

export default AddLicenseForm;

import gql from "graphql-tag";

export const GET_REGISTERED_STUDENTS = gql`
  query GET_REGISTERED_STUDENTS(
    $campId: ID
    $filter: String
    $limit: Int
    $order: String
  ) {
    getRegisteredStudents(
      campId: $campId
      filter: $filter
      limit: $limit
      order: $order
    ) {
      id
      student_id
      address1
      address2
      auto_approval
      camp_description
      camp_email
      camp_fees
      camp_id
      camp_name
      camp_name
      camp_phone
      camp_url
      city
      country
      created_on
      default_jump_price
      discipline
      dob
      email
      end
      event_type_name
      first_name
      gender
      height
      is_public
      jump_quantity
      jump_weight
      last_name
      location
      location_id
      nac
      registrationLocation
      phone_country_code
      phone_number
      postal_code
      profile_avatar
      profile_picture
      province
      regId
      registration_code
      registration_id
      rating
      role
      shirt_size_id
      shirt_size
      slot_duration
      start
      status
      status_color
      status_icon
      user_registrations_id
      weight

      payments {
        id
        amount
        processed_by
        manual_payment
        status
        created_on
      }

      registrationQuestions {
        name
        user_answer
        user_registration_question_id
      }

      totalTime {
        total_duration
      }

      user_registration_options {
        id
        registration_option_id
        name
        option_value
        quantity
        fee_type
      }

      uspa {
        isLicenseExpired
        isLicenseExpiring
        uspaStatus
      }

      home_dz {
        id
        name
        description
        is_frozen
        key
        type
      }

      home_tunnel {
        id
        name
        description
        is_frozen
        key
        type
      }

      aad {
        name
        gear_model {
          name
        }
      }
      main {
        name
        gear_model {
          name
        }
        gear_canopy_size
      }
      orgCredentials {
        id
        org_id
        org_name
        org_slug
        student_id
        membership
        expires
        license
        license_number
        rating
        verified_on
        verified_by
        created_on
        image
        nac_id
        nac_country
        nac_slug
        nac_name
      }

      campGroups {
        group_id
        name
      }
    }
  }
`;

import {
  Document,
  Image,
  Page,
  Path,
  Svg,
  Text as PDFText,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import React, { useMemo } from "react";
import styles from "utils/pdfStyles";
import { Types } from "../../../types/graphql";
import { groupBySubHeader } from "../StudentTeamProgressionHeading";

interface ISkillsPDFProps {
  progression: Types.StudentTeamProgression[];
  heading: string;
  studentDetails: any;
}

const SkillsPdf = ({
  progression,
  heading,
  studentDetails,
}: ISkillsPDFProps) => {
  const groupedProgression = groupBySubHeader(progression);
  const overAllRate = useMemo(() => {
    if (
      progression.filter((p: Types.StudentTeamProgression) => p.is_exist)
        .length > 0
    ) {
      return (
        (progression.filter(
          (p: Types.StudentTeamProgression) =>
            p.is_exist && p.studentProgressionStatus === 1,
        ).length /
          progression.filter((p: Types.StudentTeamProgression) => p.is_exist)
            .length) *
        100
      ).toFixed(0);
    }
  }, [progression]);

  return (
    <Document>
      <Page style={styles.body}>
        {studentDetails.teamLogo && (
          <Image
            src={studentDetails.teamLogo}
            style={{ width: 100, height: 100 }}
          />
        )}

        <View>
          <View style={{ ...styles.tableCol, width: "70%" }}>
            <PDFText style={{ ...styles.title, marginBottom: "20px" }}>
              {studentDetails.studentName}
            </PDFText>
            <PDFText style={{ ...styles.textMedium }}>
              Score Card: {heading}
            </PDFText>
            <PDFText style={{ ...styles.textMedium }}>
              {studentDetails.teamName}
            </PDFText>
            <PDFText style={{ ...styles.textMedium }}>
              {studentDetails.campName}
            </PDFText>
            <PDFText style={{ ...styles.textSmall }}>
              {studentDetails.campDuration}
            </PDFText>
            <PDFText style={{ ...styles.textSmall, marginBottom: "20px" }}>
              {studentDetails.campLocation}
            </PDFText>
          </View>
          {/*<View style={{ ...styles.tableCol, width: "100%" }}>*/}
          {/*  <PDFText style={{ ...styles.textMedium, textAlign: "right" }}>*/}
          {/*    {overAllRate}%*/}
          {/*  </PDFText>*/}
          {/*</View>*/}
        </View>

        {Object.keys(groupedProgression)
          .sort()
          .map((key) => (
            <View style={styles.table} key={key}>
              <View style={styles.tableRow}>
                <PDFText
                  style={{
                    ...styles.tableCol,
                    ...styles.textMedium,
                    width: "100%",
                  }}
                >
                  {key}
                </PDFText>
              </View>
              {groupedProgression[key].map(
                (pr: Types.StudentTeamProgression, j) => (
                  <View key={j} style={styles.tableRow}>
                    <View
                      style={{
                        ...styles.tableCol,
                        width: "70%",
                      }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <PDFText
                          style={{
                            ...styles.textSmall,
                          }}
                        >
                          {pr.title}
                        </PDFText>
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <PDFText
                          style={{
                            ...styles.textSmall,
                            color: "grey",
                          }}
                        >
                          {pr.studentProgressionNotes ?? ""}
                        </PDFText>
                      </View>
                    </View>

                    <PDFText
                      style={{
                        ...styles.tableCol,
                        ...styles.textSmall,
                        width: "20%",
                        textAlign: "right",
                      }}
                    >
                      {pr.rating}
                    </PDFText>
                    <View
                      style={{
                        ...styles.tableCol,
                        width: "10%",
                        textAlign: "right",
                      }}
                    >
                      {pr.studentProgressionStatus === 1 && (
                        <Svg
                          viewBox="0 0 24 24"
                          style={{ width: 16, height: 16, marginLeft: 4 }}
                        >
                          <Path
                            d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
                            fill="green"
                          />
                        </Svg>
                      )}
                    </View>
                  </View>
                ),
              )}
            </View>
          ))}

        <PDFText style={{ ...styles.textSmall, marginBottom: "20px" }}>
          Generated: {moment().format("MMM/DD/YYYY")}
        </PDFText>
      </Page>
    </Document>
  );
};

export default SkillsPdf;

import Modal from "components/Modal";
import React from "react";
import AddMembershipForm from "../forms/AddMembershipForm";

interface Props {
  // Define your component's props here
  orgId: number;
  orgName: string;
  studentId: number;
  toggle?: () => void;
  isOpen: boolean;
}

const AddMembershipModal: React.FC<Props> = ({
  orgId,
  orgName,
  studentId,
  toggle,
  isOpen,
}) => {
  // Add your component logic here

  return (
    // Add your JSX code here
    <Modal
      title={"Add Organization Membership"}
      onClose={toggle}
      open={isOpen}
      content={
        <AddMembershipForm
          orgId={orgId}
          orgName={orgName}
          studentId={studentId}
          toggle={toggle}
        />
      }
    />
  );
};

export default AddMembershipModal;

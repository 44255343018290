import { useQuery } from "@apollo/client";
import Box from "@material-ui/core/Box";
import CalendarLink from "components/Calendar/CalendarLink";
import EventRegistrationListItem from "components/Event/EventRegistrationListItem";
import { CAMP_HAS_JUMP_TICKETS } from "graphql/CAMP_HAS_JUMP_TICKETS";
import { GET_CAMP_BY_ID } from "graphql/GET_CAMP_BY_ID";
import { GET_CAMP_REGISTRATION_REQUIREMENTS } from "graphql/GET_CAMP_REGISTRATION_REQUIREMENTS";
import { CAMP_HAS_JUMP_TICKETS as CAMP_HAS_JUMP_TICKETS_TYPE } from "graphql/types/CAMP_HAS_JUMP_TICKETS";
import { GET_CAMP_BY_ID as GET_CAMP_BY_ID_TYPE } from "graphql/types/GET_CAMP_BY_ID";
import FeatureAccessGate from "modules/common/components/FeatureAccessGate";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import { EPaymentStatus } from "modules/payment/constants/paymentStatus";
import { calcTotalByStatus } from "modules/payment/utils/calcTotalPaid";
import AddJumpTicketsButton from "modules/registration/options/components/buttons/AddJumpTicketsButton";
import ResetJumpTicketsButton from "modules/registration/options/components/buttons/ResetJumpTicketsButton";
import TShirtDetailsButton from "modules/registration/options/components/buttons/TShirtDetailsButton";
import SendEmailToEventRegisteredUsersButton from "modules/user/registrations/components/buttons/SendEmailToEventRegisteredUsersButton";
import EventRegistrationTotalConfirmed from "modules/user/registrations/components/stamps/EventRegistrationTotalConfirmed";
import EventRegistrationTotalPayments from "modules/user/registrations/components/stamps/EventRegistrationTotalPayments";
import EventRegistrationTotalPendingRevenue from "modules/user/registrations/components/stamps/EventRegistrationTotalPendingRevenue";
import useGetRegisteredStudents from "modules/user/registrations/hooks/useGetRegisteredStudents";
import { calcTotalFees } from "modules/user/registrations/utils/calcTotalFees";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { CSVLink } from "react-csv";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router";
import {
  Button,
  Card,
  Dimmer,
  Dropdown,
  Grid,
  StampCard,
  Table,
  Text,
} from "tabler-react";
import useReactRouter from "use-react-router";
import { getWingLoad } from "utils/numberFormat";
import { useRootStore } from "../../hooks";
import useGetEventOrganizers from "../../modules/user/roles/hooks/useGetEventOrganizers";
import PageNoAccess from "../../pages/PageNoAccess";
import { Types } from "../../types/graphql";

const EventRegistrationListSkeleton = () => {
  return (
    <Box>
      <Box pb={2}>
        <Skeleton height={65} width="100%" />
      </Box>
      <Box py={2}>
        <Skeleton height={65} />
      </Box>
      <Box py={2}>
        <Skeleton height={65} />
      </Box>
      <Box pb={2}>
        <Skeleton height={65} />
      </Box>
      <Box py={2}>
        <Skeleton height={65} />
      </Box>
      <Box py={2}>
        <Skeleton height={65} />
      </Box>
    </Box>
  );
};

const EventRegistrationList = () => {
  const { history } = useReactRouter();
  const { currentCoachTeam } = useRootStore();
  const { campId } = useParams<{ campId: string }>();
  const { isAdmin, isCoachSuperAdmin, isOrg } = useGetCurrentUserType();

  const { data: campData, loading: campLoading } =
    useQuery<GET_CAMP_BY_ID_TYPE>(GET_CAMP_BY_ID, {
      variables: {
        campId: Number(campId),
      },
      fetchPolicy: "network-only",
    });

  const { data: campJumpTickets } = useQuery<CAMP_HAS_JUMP_TICKETS_TYPE>(
    CAMP_HAS_JUMP_TICKETS,
    {
      variables: {
        campId: Number(campId),
      },
    },
  );

  const campHasJumpTickets = useMemo(() => {
    return campJumpTickets?.campHasJumpTickets;
  }, [campJumpTickets?.campHasJumpTickets]);

  const { data, loading } = useGetRegisteredStudents({ campId });

  const { data: campRequirementsData, loading: campRequirementsLoading } =
    useQuery(GET_CAMP_REGISTRATION_REQUIREMENTS, {
      variables: {
        team_id: Number(campData?.getCampById.team_id),
        camp_id: Number(campId),
      },
      skip: !campData?.getCampById.team_id,
    });

  const campRequirements = useMemo(() => {
    if (campRequirementsLoading) return [];

    return campRequirementsData?.campRegistrationRequirements
      .filter((r) => r.is_exist)
      .map((r) => r.requirement_type_slug);
  }, [campRequirementsData, campRequirementsLoading]);

  const isShirtRequired = useMemo(() => {
    if (campRequirementsLoading) return false;

    return !!campRequirementsData?.campRegistrationRequirements.find(
      (r) => r.is_exist && r.requirement_type_slug === "shirt_size",
    );
  }, [campRequirementsData, campRequirementsLoading]);

  const registeredStudents = useMemo(() => {
    if (!loading && data) {
      return data?.getRegisteredStudents.map((student) => student);
    }

    return [];
  }, [data?.getRegisteredStudents]);

  const registrationList = useMemo(
    () =>
      data?.getRegisteredStudents.map((regStudent) => {
        const {
          id,
          camp_fees,
          default_jump_price,
          email,
          first_name,
          jump_quantity,
          last_name,
          payments,
          phone_country_code,
          phone_number,
          registrationQuestions,
          registration_code,
          rating,
          slot_duration,
          status,
          totalTime,
          user_registration_options,
          uspa,
          home_dz,
          home_tunnel,
          aad,
          main,
        } = regStudent;

        const totalFees = calcTotalFees({
          camp_fees,
          user_registration_options,
          slot_duration,
          totalTime,
          default_jump_price,
          jump_quantity,
        });

        const totalPaid = calcTotalByStatus(
          payments.map(({ amount, status }) => ({
            amount,
            status,
          })),
          EPaymentStatus.paid,
        );

        const totalComp = calcTotalByStatus(
          payments.map(({ amount, status }) => ({
            amount,
            status,
          })),
          EPaymentStatus.comp,
        );

        // toFixed(2) is used to round to 2 decimal places
        const fixedRemainingBalance = (
          totalFees -
          totalPaid -
          totalComp
        ).toFixed(2);
        const remainingBalance = totalFees - totalPaid - totalComp;

        // Create object to return
        const ignoreList = [
          "name",
          "email",
          "phone",
          "merit_sync",
          "profile_photo",
          "profile_avatar",
          "min_time",
        ];
        const reqVals = {};

        campRequirements
          .filter((req) => !ignoreList.includes(req))
          .forEach((req) => {
            const keys = Object.keys(regStudent);
            if (keys.includes(req) || req === "wing_load") {
              switch (req) {
                case "wing_load":
                  reqVals[req] =
                    main?.gear_canopy_size && regStudent.weight
                      ? getWingLoad(main?.gear_canopy_size, regStudent.weight)
                      : "";
                  break;
                case "aad":
                  reqVals[req] = aad?.gear_model.name ?? "";
                  break;
                case "home_dz":
                  reqVals[req] = home_dz?.name ?? "";
                  break;
                case "home_tunnel":
                  reqVals[req] = home_tunnel?.name ?? "";
                  break;
                case "dob":
                  reqVals[req] = regStudent[req]
                    ? moment(regStudent[req]).format("MM/DD/YYYY")
                    : "";
                  break;
                case "location":
                  reqVals[req] = regStudent?.registrationLocation ?? "";
                  break;
                default:
                  reqVals[req] = regStudent[req];
                  break;
              }
            }
          });

        return {
          id,
          first_name,
          last_name,
          status,
          email,
          phone_country_code,
          phone_number,
          registration_code,
          rating,
          paid_status: remainingBalance > 0 ? "Pending" : "Paid",
          totalFees,
          totalPaid,
          totalComp,
          remainingBalance: fixedRemainingBalance,
          uspa: uspa.uspaStatus,
          ...reqVals,
          ...registrationQuestions?.reduce(
            (acc, { name, user_answer }) => ({
              ...acc,
              [name]: user_answer,
            }),
            {},
          ),
        };
      }) ?? [],
    [data?.getRegisteredStudents],
  );

  const { isOrganizer, eventOrganizersDataLoading } = useGetEventOrganizers({
    eventId: Number(campId),
  });

  const hasAccess = useCallback(() => {
    return (
      isOrganizer ||
      (currentCoachTeam &&
        currentCoachTeam?.id === Number(campData?.getCampById?.team_id)) ||
      isAdmin
    );
  }, [currentCoachTeam, campData, isAdmin, isOrganizer]);

  if (!eventOrganizersDataLoading && !campLoading && !hasAccess()) {
    return <PageNoAccess />;
  }

  return (
    <Dimmer
      active={eventOrganizersDataLoading || campLoading}
      loader={eventOrganizersDataLoading || campLoading}
    >
      <div className="container">
        <Grid.Row>
          <Text>
            <strong>Registration Status & Fees</strong>
          </Text>
        </Grid.Row>
        <Grid.Row className="mt-2">
          <EventRegistrationTotalConfirmed />
          <EventRegistrationTotalPayments />
          <EventRegistrationTotalPendingRevenue />
        </Grid.Row>
        <FeatureAccessGate feature="feature_alpha">
          <Grid.Row>
            <Text>
              <strong>Loads & Jump Tickets</strong>
            </Text>
          </Grid.Row>
          <Grid.Row className="mt-2">
            <Grid.Col sm={6} lg={3} xs={12} className="px-0 mb-2">
              <StampCard
                className="mb-0"
                color="info"
                icon="users"
                header={
                  <Text>
                    {"Day 1"} <small>Aug 11</small>
                  </Text>
                }
                footer={<Text>1 Load | 14 Jump Tickets | $980</Text>}
              />
            </Grid.Col>
            <Grid.Col sm={6} lg={3} xs={12} className="px-0 mb-2">
              <StampCard
                className="mb-0"
                color="info"
                icon="users"
                header={
                  <Text>
                    {"Day 2"} <small>Aug 11</small>
                  </Text>
                }
                footer={<Text>1 Load | 14 Jump Tickets | $980</Text>}
              />
            </Grid.Col>
            <Grid.Col sm={6} lg={3} xs={12} className="px-0 mb-2">
              <StampCard
                className="mb-0"
                color="info"
                icon="users"
                header={
                  <Text>
                    {"Day 3"} <small>Aug 11</small>
                  </Text>
                }
                footer={<Text>1 Load | 14 Jump Tickets | $980</Text>}
              />
            </Grid.Col>
            <Grid.Col sm={6} lg={3} xs={12} className="px-0 mb-2">
              <StampCard
                className="mb-0"
                color="info"
                icon="users"
                header={
                  <Text>
                    {"Day 4"} <small>Aug 11</small>
                  </Text>
                }
                footer={<Text>1 Load | 14 Jump Tickets | $980</Text>}
              />
            </Grid.Col>
          </Grid.Row>
        </FeatureAccessGate>

        <Grid.Row className="mt-4">
          <Card>
            <Card.Header>
              <Card.Title>
                <strong>
                  <CalendarLink
                    eventName={campData?.getCampById.camp_name}
                    eventId={Number(campId)}
                  />
                </strong>
                {} // Registered Participants
              </Card.Title>

              <Card.Options>
                <Button.Dropdown
                  key="dropdown"
                  icon="more-vertical"
                  color="white"
                  dropdownProps={{
                    className: "w-25",
                  }}
                >
                  {isShirtRequired && (
                    <TShirtDetailsButton registrationList={registrationList} />
                  )}

                  {campHasJumpTickets && <ResetJumpTicketsButton />}

                  {!campHasJumpTickets && <AddJumpTicketsButton />}

                  <SendEmailToEventRegisteredUsersButton />
                  <Dropdown.Item
                    icon="users"
                    color="white"
                    className="cursor-pointer"
                    onClick={() => history.push(`/event/groups/${campId}`)}
                  >
                    GROUPS
                  </Dropdown.Item>
                  {!loading && registrationList && !isAdmin && (
                    <Dropdown.Item
                      icon="download"
                      color="white"
                      className="cursor-pointer"
                    >
                      <CSVLink
                        data={registrationList}
                        filename="registration-list.csv"
                        className="ml-0"
                      >
                        CSV
                      </CSVLink>
                    </Dropdown.Item>
                  )}
                  {(isAdmin || isCoachSuperAdmin || isOrg) && (
                    <>
                      <Dropdown.Item
                        icon="list"
                        color="white"
                        className="cursor-pointer"
                        onClick={() => history.push(`/event/controller/${campId}`)}
                      >
                        Controller Report
                      </Dropdown.Item>
                      <Dropdown.Item
                        icon="list"
                        color="white"
                        className="cursor-pointer"
                        onClick={() => history.push(`/event/finance/${campId}`)}
                      >
                        Finance Report
                      </Dropdown.Item>
                    </>
                  )}
                </Button.Dropdown>
              </Card.Options>
            </Card.Header>
            <Card.Body>
              <Grid.Col width={12}>
                {loading ? (
                  <EventRegistrationListSkeleton />
                ) : (
                  <Table
                    responsive
                    className="card-table table-vcenter text-nowrap"
                  >
                    <Table.Header>
                      <Table.Row>
                        {isAdmin && <Table.ColHeader>ID</Table.ColHeader>}
                        <Table.ColHeader></Table.ColHeader>
                        <Table.ColHeader></Table.ColHeader>
                        <Table.ColHeader></Table.ColHeader>
                        <Table.ColHeader></Table.ColHeader>
                        <Table.ColHeader></Table.ColHeader>
                        <Table.ColHeader></Table.ColHeader>
                        <Table.ColHeader></Table.ColHeader>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {registeredStudents.map(
                        (student: Types.RegisteredStudents) => (
                          <Table.Row key={student.user_registrations_id}>
                            <EventRegistrationListItem student={student} />
                          </Table.Row>
                        ),
                      )}
                    </Table.Body>
                  </Table>
                )}
              </Grid.Col>
            </Card.Body>
          </Card>
        </Grid.Row>
      </div>
    </Dimmer>
  );
};

export default EventRegistrationList;

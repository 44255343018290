import { useMutation, useQuery } from "@apollo/client";
import FormField from "components/FormField";
import TextEditor from "components/text-editor/TextEditor";
import { Formik } from "formik";
import { UPDATE_CAMP_BY_ID } from "graphql/UPDATE_CAMP_BY_ID";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Card, Form, Grid, Text } from "tabler-react";
import * as Yup from "yup";
import { GET_CAMP_COHOSTS } from "../../../graphql/GET_CAMP_COHOSTS";
import { GET_NOTIFICATION_EMAILS } from "../../../graphql/GET_NOTIFICATION_EMAILS";
import { GET_TEAM_ORGS } from "../../../graphql/GET_TEAM_ORGS";
import useGetCurrentUserType from "../../../modules/common/hooks/useGetCurrentUserType";
import { AddNotificationEmailForm } from "../../../modules/notification-email/components/forms/AddNotificationEmailForm";
import NotificationEmailListItem from "../../../modules/notification-email/components/items/NotificationEmailListItem";
import { Types } from "../../../types/graphql";
import CurrencyInputField from "../../CurrencyInputField";
import { EEventTypes } from "../../enum";
import { currencyOptions } from "../../Team/Form/TeamProfile";

interface ICalendarEventEditProps {
  event: Types.Camp;
  setIsEditing: (_: string) => void;
}

const editCampValidationSchema = Yup.object().shape({
  registration_currency: Yup.string().required("Currency is required"),
});

const CalendarEventEdit = ({
  event,
  setIsEditing,
}: ICalendarEventEditProps) => {
  const [updateCampById] = useMutation(UPDATE_CAMP_BY_ID, {
    refetchQueries: ["GET_CAMP_BY_ID", "GET_CAMPS", "GET_CAMP_COHOSTS"],
  });
  const [selectedTeams, setSelectedTeams] = React.useState<number[]>([]);
  const [teams, setTeams] = React.useState<Types.CampHostTeam[]>([]);
  const [teamUpdated, setTeamUpdated] = React.useState(false);
  const { isAdmin, isCoachSuperAdmin } = useGetCurrentUserType();
  const { data, loading } = useQuery(GET_NOTIFICATION_EMAILS, {
    variables: { campId: event.id },
  });

  const { data: orgsData, loading: orgsLoading } = useQuery(GET_TEAM_ORGS, {
    variables: { teamId: event.team_id },
  });

  const orgOptions = useMemo(() => {
    if (!orgsLoading && orgsData) {
      return orgsData.teamOrgs
        .filter((org) => org.exist)
        .map((org) => ({
          label: org.name,
          value: org.org_id,
        }));
    }
    return [];
  }, [orgsData, orgsLoading]);

  const { loading: loadingTeams, data: dataTeams } = useQuery(
    GET_CAMP_COHOSTS,
    {
      variables: {
        campId: Number(event.id),
      },
      fetchPolicy: "network-only",
    },
  );

  const handleDateValidation = (start, end) => {
    if (moment(end).isBefore(moment(start))) {
      return "Start date must be earlier than end date";
    }

    return "";
  };

  const changeTeamHandle = (selectedTeams: Types.CampHostTeam[]) => {
    setTeamUpdated(true);
    if (selectedTeams) {
      const selected: number[] = selectedTeams.map(
        (team: Types.CampHostTeam) => {
          return team.id;
        },
      );

      setSelectedTeams(
        selected.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        }),
      );

      setTeams(
        teams
          .map((team: Types.CampHostTeam) => {
            const cloneTeam = { ...team };
            if (selected.includes(cloneTeam.id)) {
              cloneTeam.is_active = true;
            } else {
              cloneTeam.is_active = false;
            }
            return cloneTeam;
          })
          .filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
          }),
      );
    } else {
      setTeams(
        teams.filter((team: Types.CampHostTeam) => {
          const cloneTeam = { ...team };
          cloneTeam.is_active = false;
          return cloneTeam;
        }),
      );
    }
  };

  useEffect(() => {
    if (!loadingTeams && dataTeams) {
      setTeams(
        [...dataTeams.getCampCoHosts].filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        }),
      );
    }
  }, [loadingTeams, dataTeams]);

  const renderForm = ({
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    dirty,
    errors,
    touched,
  }) => (
    <>
      <Grid.Row>
        <Grid.Col xs={12} sm={12} md={12} lg={8}>
          <h3 className="ml-1 mt-2">{event.camp_name}</h3>
        </Grid.Col>
        <Grid.Col xs={12} sm={12} md={12} lg={4}>
          <Grid.Row>
            <Grid.Col lg={8}>
              <Button
                block
                color="primary"
                className="mb-3"
                type="submit"
                onClick={handleSubmit}
                disabled={!dirty && !teamUpdated}
              >
                UPDATE
              </Button>
            </Grid.Col>
            <Grid.Col lg={4}>
              <Button
                block
                outline
                color="secondary"
                className="mb-3"
                onClick={() => setIsEditing("view")}
              >
                DONE
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={12} md={12} lg={8}>
          <Card>
            <Form onSubmit={handleSubmit}>
              <Card.Body>
                <Grid.Row className="mb-3">
                  <Grid.Col md={12} lg={12} sm={12}>
                    <Grid.Row>
                      <Grid.Col width={12}>
                        <Form.Input
                          label="Event Name"
                          name="camp_name"
                          onChange={handleChange}
                          value={values.camp_name}
                          className="mb-3"
                          type="text"
                          placeholder="Event Name"
                        />
                      </Grid.Col>
                      <Grid.Col sm={12} md={6} lg={6}>
                        <FormField
                          label="Start Date"
                          type="date"
                          name="start_date"
                          placeholder="Date"
                          onChange={handleChange}
                          value={values.start_date}
                        />
                      </Grid.Col>
                      <Grid.Col sm={12} md={6} lg={6}>
                        <FormField
                          label="End Date"
                          type="date"
                          name="end_date"
                          placeholder="Date"
                          onChange={handleChange}
                          value={values.end_date}
                        />
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col sm={12} md={6} lg={6}>
                        <div className="h6">Start Time</div>
                        <input
                          className="form-control"
                          type="time"
                          name="start_time"
                          step="900"
                          value={values.start_time}
                          onChange={handleChange}
                          disabled={values.all_day}
                        />
                      </Grid.Col>
                      <Grid.Col sm={12} md={6} lg={6}>
                        <div className="h6">End Time</div>
                        <input
                          className="form-control"
                          type="time"
                          name="end_time"
                          step="900"
                          value={values.end_time}
                          onChange={handleChange}
                          disabled={values.all_day}
                        />
                      </Grid.Col>
                    </Grid.Row>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row className="mb-3 mt-7">
                  <Grid.Col sm={12} lg={6}>
                    <div className="h6">Event Type</div>
                    <FormField
                      name="event_type_name"
                      placeholder=""
                      type=""
                      value={event.event_type_name}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} lg={6}>
                    <div className="h6">Location</div>
                    <FormField
                      name="event_location"
                      placeholder=""
                      type=""
                      value={event.location}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid.Col>
                </Grid.Row>
                {(isAdmin || isCoachSuperAdmin) &&
                  event.event_type_id === EEventTypes.competition && (
                    <Grid.Row>
                      <Grid.Col>
                        <div className="h6">Organization</div>
                        <Form.Group>
                          <Select
                            name="org_id"
                            options={orgOptions}
                            onChange={(e) => setFieldValue("org_id", e.value)}
                            value={orgOptions.find(
                              (org) => org.value === values.org_id,
                            )}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                  )}
                {(isAdmin || isCoachSuperAdmin) && (
                  <Grid.Row>
                    <Grid.Col>
                      <div className="h6">Additional Host (optional)</div>
                      <Form.Group>
                        <Select
                          name="teams"
                          isMulti
                          getOptionLabel={(option: Types.CampHostTeam) =>
                            option.name
                          }
                          getOptionValue={(option: Types.CampHostTeam) =>
                            option.id
                          }
                          options={values.teams}
                          onChange={changeTeamHandle}
                          value={values.teams.filter(
                            (team: Types.CampHostTeam) =>
                              team.is_active === true,
                          )}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                )}
                {(isAdmin || isCoachSuperAdmin) && (
                  <Grid.Row>
                    <Grid.Col>
                      <div className="h6">WhatsApp</div>
                      <FormField
                        type="text"
                        name="camp_whatsapp"
                        placeholder="WhatsApp"
                        onChange={handleChange}
                        value={values.camp_whatsapp}
                      />
                    </Grid.Col>
                    <Grid.Col>
                      <div className="h6">WhatsApp Group</div>
                      <FormField
                        type="text"
                        name="whatsapp_group"
                        placeholder="whatsAppGroup"
                        onChange={handleChange}
                        value={values.whatsapp_group}
                      />
                    </Grid.Col>
                  </Grid.Row>
                )}
                <Grid.Row className="mb-7">
                  <Grid.Col>
                    <div className="h6">Event Details</div>
                    <TextEditor
                      value={values.camp_description}
                      placeholder="Enter event details..."
                      onChange={(content) =>
                        setFieldValue("camp_description", content)
                      }
                    />
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
            </Form>
          </Card>
        </Grid.Col>
        <Grid.Col xs={12} sm={12} md={12} lg={4}>
          <Card>
            <Card.Header>
              <Card.Title>Registration Settings</Card.Title>
              <Card.Options>
                <Form.Switch
                  name="status"
                  label="Registrations"
                  checked={values.status === "Registrations"}
                  onChange={(e) => {
                    setFieldValue(
                      "status",
                      e.target.checked ? "Registrations" : "Event",
                    );
                  }}
                />
              </Card.Options>
            </Card.Header>
            <Card.Body>
              <Grid.Row className="mb-3">
                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                  <Form.Group label="Event Fee">
                    <CurrencyInputField
                      name="camp_fees"
                      value={values.camp_fees}
                      placeholder="0.00"
                      setFieldValue={setFieldValue}
                      currency={values.registration_currency ?? "USD"}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                  <Form.Group label={"Currency"}>
                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      name="registration_currency"
                      options={currencyOptions}
                      onChange={(e) => {
                        setFieldValue("registration_currency", e.value);
                      }}
                      value={currencyOptions.find(
                        (c) => c.value === values.registration_currency,
                      )}
                    />
                  </Form.Group>
                </Grid.Col>
                {event.sport_type_name === "Skydiving" && (
                  <>
                    <Grid.Col xs={12} sm={12} md={6} lg={6}>
                      <Form.Group label="Jump Tickets">
                        <CurrencyInputField
                          name="default_jump_price"
                          value={values.default_jump_price}
                          placeholder="0.00"
                          setFieldValue={setFieldValue}
                          currency={values.registration_currency ?? "USD"}
                        />
                      </Form.Group>
                    </Grid.Col>
                    {(isAdmin || isCoachSuperAdmin) && (
                      <Grid.Col xs={12} sm={12} md={6} lg={6}>
                        <FormField
                          label="Expected Jumps"
                          name="default_jump_count"
                          placeholder="0"
                          type="number"
                          value={values.default_jump_count}
                          onChange={handleChange}
                        />
                      </Grid.Col>
                    )}
                  </>
                )}
                {values.status === "Registrations" && (
                  <Grid.Col xs={12} sm={12} md={6} lg={6}>
                    <FormField
                      label="Max Registrations"
                      name="registrations_max"
                      placeholder="Unlimited"
                      type="number"
                      value={values.registrations_max}
                      onChange={handleChange}
                    />
                  </Grid.Col>
                )}
              </Grid.Row>
              {event.event_type_name === "Competition" && (
                <Grid.Row>
                  <Grid.Col xs={12} sm={12} md={6} lg={6}>
                    <Form.Group label="Sanction Fee">
                      <CurrencyInputField
                        name="sanction_fee"
                        value={values.sanction_fee}
                        placeholder="0.00"
                        setFieldValue={setFieldValue}
                        currency={values.sanction_fee_currency ?? "USD"}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={12} md={6} lg={6}>
                    <Form.Group label={"Saction Fee Currency"}>
                      <Select
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        menuPortalTarget={document.body}
                        name="sanction_fee_currency"
                        options={currencyOptions}
                        onChange={(e) => {
                          setFieldValue("sanction_fee_currency", e.value);
                        }}
                        value={currencyOptions.find(
                          (c) => c.value === values.sanction_fee_currency,
                        )}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              )}
              {(isAdmin || isCoachSuperAdmin) && (
                <Grid.Row>
                  <Grid.Col xs={12} sm={12} md={6} lg={6}>
                    <Form.Group label="Registration Fee">
                      <CurrencyInputField
                        name="registration_fee"
                        value={values.registration_fee}
                        placeholder="0.00"
                        setFieldValue={setFieldValue}
                        currency={values.registration_currency ?? "USD"}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={12} md={6} lg={6}>
                    <Form.Group label="Deposit Fee">
                      <CurrencyInputField
                        name="deposit_fee"
                        value={values.deposit_fee}
                        placeholder="0.00"
                        setFieldValue={setFieldValue}
                        currency={values.registration_currency ?? "USD"}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              )}
              {values.status === "Registrations" && (
                <Grid.Row>
                  <Grid.Col width={12}>
                    <Form.Switch
                      name="registration_status"
                      label="Registrations Open"
                      checked={values.registration_status === "open"}
                      onChange={(e) => {
                        setFieldValue(
                          "registration_status",
                          e.target.checked ? "open" : "closed",
                        );
                      }}
                    />
                  </Grid.Col>
                  {/*
                  <Grid.Col width={12}>
                    <Form.Switch
                      name="student_registration"
                      label="Participant Registration"
                      checked={!!values.student_registration}
                      onChange={(e) => {
                        setFieldValue(
                          "student_registration",
                          e.target.checked ? 1 : 0,
                        );
                      }}
                    />
                  </Grid.Col>
                  */}
                  {values.registrations_max > 0 && (
                    <Grid.Col width={12}>
                      <Form.Switch
                        name="registration_waitlist"
                        label="Waitlist"
                        checked={!!values.registration_waitlist}
                        onChange={(e) => {
                          setFieldValue(
                            "registration_waitlist",
                            e.target.checked ? 1 : 0,
                          );
                        }}
                      />
                    </Grid.Col>
                  )}

                  <Grid.Col width={12}>
                    <Form.Switch
                      name="auto_approval"
                      label="Auto Approve Registrations"
                      checked={!!values.auto_approval}
                      onChange={(e) => {
                        setFieldValue(
                          "auto_approval",
                          e.target.checked ? 1 : 0,
                        );
                      }}
                    />
                    <Text.Small className="text-muted d-block ml-7">
                      Default Status:
                      {values.auto_approval === 1 ? " Confirmed" : " Pending"}
                    </Text.Small>
                  </Grid.Col>
                </Grid.Row>
              )}
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <Card.Title>Event Contact Info</Card.Title>
            </Card.Header>
            <Card.Body>
              <Grid.Row>
                <Grid.Col>
                  <div className={"form-group"}>
                    <label className="form-label">Phone</label>
                    <PhoneInput
                      inputProps={{
                        className: "form-control w-100",
                        name: "camp_phone",
                        required: true,
                        onChange: (e) => {
                          e.preventDefault();
                          const target = e.target;
                          const phone = target.value.split(" ");
                          const countryCode = phone.shift().replace(/\D/g, "");
                          const phoneNumber = phone.join("").replace(/\D/g, "");
                          setFieldValue("camp_phone", phoneNumber);
                          setFieldValue(
                            "camp_phone_country_code",
                            Number(countryCode),
                          );
                        },
                      }}
                      value={`${values.camp_phone_country_code}${values.camp_phone}`}
                    />
                    <span className="field-error text-danger">
                      {errors.camp_phone &&
                        touched.camp_phone &&
                        errors.camp_phone}
                    </span>
                  </div>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row className="mb-3">
                <Grid.Col>
                  <label className="form-label">Email</label>
                  <Form.Input
                    icon="mail"
                    name="camp_email"
                    type="email"
                    onChange={handleChange}
                    value={values.camp_email}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row className="mb-3">
                <Grid.Col>
                  <label className="form-label">Website</label>
                  <Form.InputGroup>
                    <Form.InputGroupAppend>
                      <Button color="secondary" disabled>
                        https://
                      </Button>
                    </Form.InputGroupAppend>
                    <Form.Input
                      name="camp_url"
                      onChange={handleChange}
                      value={values.camp_url}
                    />
                  </Form.InputGroup>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <Card.Title>
                <i className="fe fe-mail text-primary mr-2" />
                Notification Emails
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group>
                    <AddNotificationEmailForm campId={Number(event.id)} />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              {!loading &&
                data &&
                data.campNotificationEmails.length > 0 &&
                data.campNotificationEmails.map((email) => (
                  <NotificationEmailListItem notificationEmail={email} />
                ))}
            </Card.Body>
            <Card.Footer>
              <Text.Small className="text-muted">
                Enter email addresses to receive notification emails for this
                event. Click to edit.
              </Text.Small>
            </Card.Footer>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </>
  );

  return (
    <Formik
      validationSchema={editCampValidationSchema}
      initialValues={{
        id: event.id,
        camp_name: event.camp_name,
        camp_description: event.camp_description,
        camp_email: event.camp_email ?? "",
        camp_fees: event.camp_fees,
        camp_phone: event.camp_phone ?? "",
        camp_phone_country_code: event.camp_phone_country_code ?? 1,
        camp_url: event.camp_url ?? "",
        camp_whatsapp: event.camp_whatsapp ?? "",
        whatsapp_group: event.whatsapp_group ?? "",
        default_jump_count: event.default_jump_count,
        default_jump_price: event.default_jump_price ?? "",
        deposit_fee: event.deposit_fee ?? 0,
        registrations_max:
          Number(event.registrations_max) > 0
            ? Number(event.registrations_max)
            : "",
        registration_currency:
          event.registration_currency ?? event.teamCurrency ?? "USD",
        registration_information: event.registration_information ?? "",
        registration_waitlist: event.registration_waitlist ? 1 : 0,
        registration_fee: event.registration_fee ?? 0,
        auto_approval: event.auto_approval ? 1 : 0,
        student_registration: event.student_registration ? 1 : 0,
        student_slots: event.student_slots ? 1 : 0,
        registration_status: event.registration_status,
        sanction_fee: event.sanction_fee ?? 0,
        sanction_fee_currency:
          event.sanction_fee_currency ?? event.teamCurrency ?? "USD",
        status: event.status,
        slot_duration: Number(event.slot_duration),
        students_per_slot: Number(event.students_per_slot),
        start: event.start,
        start_date: moment(event.start).format("YYYY-MM-DD"),
        start_time: moment(event.start).format("HH:mm"),
        end: event.end,
        end_date: moment(event.end).format("YYYY-MM-DD"),
        end_time: moment(event.end).format("HH:mm"),
        all_day: false,
        teams: teams,
        org_id: event.org ? event.org.id : null,
      }}
      enableReinitialize={true}
      onSubmit={async (values, { setSubmitting, resetForm, setFieldError }) => {
        let isError = false;

        values.default_jump_count =
          values.default_jump_count <= 0
            ? null
            : Number(values.default_jump_count);

        values.registrations_max =
          Number(values.registrations_max) <= 0
            ? null
            : Number(values.registrations_max);

        const {
          start_time,
          end_time,
          start_date,
          end_date,
          start,
          end,
          all_day,
          teams: submittedTeams,
          camp_whatsapp,
          ...fields
        } = values;

        const dateError = handleDateValidation(start_date, end_date);
        if (dateError.length > 0) {
          setFieldError("start_date", dateError);
          isError = true;
        }

        let start_formatted = moment(start_date + " " + start_time).format(
          "YYYY-MM-DD HH:mm",
        );
        let end_formatted = moment(end_date + " " + end_time).format(
          "YYYY-MM-DD HH:mm",
        );
        if (all_day) {
          start_formatted = moment(start_date + " 9:00").format(
            "YYYY-MM-DD HH:mm",
          );
          end_formatted = moment(end_date + " 17:00").format(
            "YYYY-MM-DD HH:mm",
          );
        }

        values.registrations_max = Number(values.registrations_max) ?? 0;

        if (
          event.registration_type === "slots" &&
          (values.students_per_slot > 6 || values.students_per_slot < 1)
        ) {
          setFieldError(
            "students_per_slot",
            "Please enter a number between 1-6.",
          );
          isError = true;
        }

        if (isError) {
          setSubmitting(false);
          return;
        }

        await updateCampById({
          variables: {
            camp: {
              ...fields,
              camp_whatsapp: camp_whatsapp
                .replace(/^0+/, "")
                .replace(/\D/g, ""),
              start: start_formatted,
              end: end_formatted,
              default_jump_price:
                Number(values.default_jump_price) <= 0
                  ? null
                  : Number(values.default_jump_price),
              registrations_max:
                Number(values.registrations_max) <= 0
                  ? null
                  : Number(values.registrations_max),
              registration_waitlist:
                Number(values.registrations_max) <= 0
                  ? 0
                  : Number(values.registration_waitlist),
              teams: selectedTeams ?? [],
            },
          },
        });

        setSubmitting(false);
        resetForm();
        toast.success("Event Updated.");
        setIsEditing("view");
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  );
};

export default observer(CalendarEventEdit);

import { useQuery } from "@apollo/client";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Loading } from "components/Loading";
import { GET_PAYMENT_FEE_BY_TEAM_ID } from "graphql/GET_PAYMENT_FEE_BY_TEAM_ID";
import { GET_STRIPE_PUBLIC_KEY } from "graphql/GET_STRIPE_PUBLIC_KEY";
import { GET_PAYMENT_FEE_BY_TEAM_ID as GET_PAYMENT_FEE_BY_TEAM_ID_TYPE } from "graphql/types/GET_PAYMENT_FEE_BY_TEAM_ID";
import { ECampFeeTypes } from "modules/camp/constants";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import RegistrationPaymentModal from "modules/user/registrations/components/modals/RegistrationPaymentModal";
import React, { useMemo, useState } from "react";
import { Button, Card, Grid, Icon, Text } from "tabler-react";
import { formatMoney } from "utils/numberFormat";
import { useRootStore } from "../../hooks";
import { EUserTypes } from "../User/userTypes";

const PaymentsSidebar = (props) => {
  const { loading: loading1, data: stripeData } = useQuery(
    GET_STRIPE_PUBLIC_KEY,
  );
  const stripeKey = useMemo(
    () => stripeData?.getStripePublicKey,

    [stripeData?.getStripePublicKey],
  );

  const { loading: loading2, data: paymentData } =
    useQuery<GET_PAYMENT_FEE_BY_TEAM_ID_TYPE>(GET_PAYMENT_FEE_BY_TEAM_ID, {
      variables: {
        teamId: props.userRegistrationDetails?.team_id,
      },
    });

  if (loading1 || loading2) {
    return <Loading />;
  }

  const paymentFee =
    paymentData?.getPaymentFeeByTeamId.payment_fee_customer / 100;
  return (
    <PaymentsSidebarInner
      {...props}
      stripeKey={stripeKey}
      paymentFee={paymentFee}
    />
  );
};

const PaymentsSidebarInner = ({
  userRegistrationDetails,
  stripeKey,
  paymentFee,
}) => {
  const { total, totalPaid, totalFees, outstanding } =
    userRegistrationDetails.paymentDetail;
  const { currentUser } = useRootStore();
  const { isCoachSuperAdmin, isStudent } = useGetCurrentUserType();
  const [invoicePaymentModalOpen, setInvoicePaymentModalOpen] = useState(false);
  const toggleInvoicePaymentModal = () =>
    setInvoicePaymentModalOpen(!invoicePaymentModalOpen);
  const canPay =
    outstanding > 0 &&
    (currentUser.type !== EUserTypes.student ||
      Number(userRegistrationDetails.student_id) === currentUser.id);

  return (
    <>
      <Card xs={12} sm={12} lg={6}>
        <Card.Header>
          <Card.Title>
            <i className="fe fe-credit-card text-success mr-2" />
            Payments & Fees
          </Card.Title>
        </Card.Header>
        {(!isStudent ||
          (isStudent && userRegistrationDetails.status === "Confirmed")) && (
          <>
            <Card.Body>
              <Grid.Row className="mb-3">
                <Grid.Col>
                  {userRegistrationDetails?.camp_fees > 0 ? (
                    <>
                      <Text className="font-weight-bold float-right">
                        {formatMoney(userRegistrationDetails?.camp_fees)}
                      </Text>
                      <Text>Registration Fee</Text>
                    </>
                  ) : (
                    ""
                  )}
                  {userRegistrationDetails?.default_jump_price && (
                    <>
                      <Text className="font-weight-bold float-right">
                        {formatMoney(
                          userRegistrationDetails?.default_jump_price *
                            userRegistrationDetails?.jump_quantity,
                        )}
                      </Text>
                      <Text>
                        Jump Tickets x {userRegistrationDetails?.jump_quantity}
                      </Text>
                    </>
                  )}
                  {userRegistrationDetails?.user_registration_options.map(
                    (option) => (
                      <CampFeeItem
                        option={option}
                        userRegistration={userRegistrationDetails}
                        key={option.id}
                      />
                    ),
                  )}
                </Grid.Col>
              </Grid.Row>
              {totalFees ? (
                <>
                  <Grid.Row>
                    <Grid.Col>
                      <Text className="text-success font-weight-bold float-right">
                        {userRegistrationDetails && formatMoney(total / 100)}
                      </Text>
                      <Text className="font-weight-bold">Grand Total:</Text>
                    </Grid.Col>
                  </Grid.Row>
                  {totalFees !== 0 && (
                    <Grid.Row>
                      <Grid.Col>
                        <Text className="text-muted font-weight-bold float-right">
                          {userRegistrationDetails &&
                            formatMoney(totalFees / 100)}
                        </Text>
                        <Text className="font-weight-bold">Fees:</Text>
                      </Grid.Col>
                    </Grid.Row>
                  )}
                </>
              ) : (
                <></>
              )}
            </Card.Body>
            <Card.Footer>
              <Grid.Row>
                <Grid.Col>
                  <Text
                    className={
                      "font-weight-bold float-right " +
                      (totalPaid === 0 ? "" : "text-success")
                    }
                  >
                    {userRegistrationDetails && formatMoney(totalPaid / 100)}
                  </Text>
                  <Text className="font-weight-bold">Paid:</Text>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Text
                    className={
                      "font-weight-bold float-right " +
                      (outstanding === 0 ? "" : "text-danger")
                    }
                  >
                    {userRegistrationDetails && formatMoney(outstanding / 100)}
                  </Text>
                  <Text className="font-weight-bold">Balance:</Text>
                </Grid.Col>
              </Grid.Row>
            </Card.Footer>
          </>
        )}
        {(isCoachSuperAdmin || (canPay && !isStudent)) && (
          <Card.Footer>
            <Button.List align="right">
              <Button color="success" onClick={toggleInvoicePaymentModal}>
                <Icon name="credit-card" className="mr-2" />
                Payment
              </Button>
            </Button.List>
          </Card.Footer>
        )}
      </Card>
      {stripeKey && invoicePaymentModalOpen && canPay && (
        <Elements stripe={loadStripe(stripeKey)}>
          <RegistrationPaymentModal
            amount={outstanding / 100}
            currency={userRegistrationDetails?.team_currency}
            isModalOpen={invoicePaymentModalOpen}
            name={`${userRegistrationDetails?.first_name} ${userRegistrationDetails?.last_name}`}
            paymentFee={paymentFee}
            toggleModal={toggleInvoicePaymentModal}
            userRegistrationDetails={userRegistrationDetails}
          />
        </Elements>
      )}
    </>
  );
};

const CampFeeItem = ({ option, userRegistration }) => {
  let amount = 0;
  switch (option.fee_type) {
    case ECampFeeTypes.slot:
      amount =
        (userRegistration?.totalTime.total_duration /
          userRegistration?.slot_duration) *
        option.option_value;
      break;
    case ECampFeeTypes.hour:
      amount = option.quantity
        ? option.quantity * option.option_value
        : (Number(userRegistration?.totalTime.total_duration) / 60) *
          Number(option.option_value);
      break;
    case ECampFeeTypes.event:
      amount = option.quantity * option.option_value;
      break;
    case ECampFeeTypes.jump:
      amount = option.quantity * option.option_value;
      break;
    case ECampFeeTypes.competitor:
      amount = option.quantity * option.option_value;
      break;
    case ECampFeeTypes.discount:
      amount = option.quantity * option.option_value;
      break;
    case ECampFeeTypes.day:
    default:
      amount = 0;
      break;
  }
  return (
    <Text>
      {option.name} {option.quantity > 1 ? "(x " + option.quantity + ")" : ""}{" "}
      <span
        className={`font-weight-bold float-right ${
          option.fee_type === ECampFeeTypes.discount && "text-muted"
        }`}
      >
        {option.fee_type === ECampFeeTypes.discount && "-"}{" "}
        {formatMoney(amount)}
      </span>
    </Text>
  );
};

export default PaymentsSidebar;

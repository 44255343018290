import { useMutation } from "@apollo/client";
import CalendarEventQuestions from "components/Calendar/Forms/CalendarEventQuestions";
import CalendarEventRegistrations from "components/Calendar/Forms/CalendarEventRegistrations";
import CalendarEventRequirements from "components/Calendar/Forms/CalendarEventRequirements";
import CalendarItemEditEmail from "components/Calendar/Forms/CalendarItemEditEmail";
import FormField from "components/FormField";
import { Formik } from "formik";
import { UPDATE_CAMP_BY_ID } from "graphql/UPDATE_CAMP_BY_ID";
import React from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Card, Form, Grid } from "tabler-react";
import { UPDATE_CAMP_DISCIPLINES } from "../../../graphql/UPDATE_CAMP_DISCIPLINES";
import { UPDATE_CAMP_ROLES } from "../../../graphql/UPDATE_CAMP_ROLES";
import CampDisciplineSelect from "../../../modules/camp/components/Select/CampDisciplineSelect";
import CampRolesSelect from "../../../modules/camp/components/Select/CampRolesSelect";
import useGetCurrentUserType from "../../../modules/common/hooks/useGetCurrentUserType";
import { Types } from "../../../types/graphql";
import { EEventTypes } from "../../enum";

interface CalendarItemEditRegistrationProps {
  event: Types.Camp;
  setIsEditing: (_: string) => void;
}

const CalendarItemEditRegistration = ({
  event,
  setIsEditing,
}: CalendarItemEditRegistrationProps) => {
  const slotDurationOptions = [
    { label: "0:10", value: 10 },
    { label: "0:15", value: 15 },
    { label: "0:30", value: 30 },
    { label: "1:00", value: 60 },
  ];

  const { isAdmin, isOrg, isCoachSuperAdmin } = useGetCurrentUserType();

  const [updateCampById] = useMutation(UPDATE_CAMP_BY_ID, {
    refetchQueries: ["GET_CAMP_BY_ID", "GET_CAMPS"],
  });
  const [updateCampRoles] = useMutation(UPDATE_CAMP_ROLES, {
    refetchQueries: ["GET_CAMP_ROLES"],
  });
  const [updateCampDisciplines] = useMutation(UPDATE_CAMP_DISCIPLINES, {
    refetchQueries: ["GET_CAMP_DISCIPLINES"],
  });

  const [campRoles, setCampRoles] = React.useState([]);
  const [campDisciplines, setCampDisciplines] = React.useState([]);

  const renderForm = ({
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    dirty,
  }) => (
    <>
      <Grid.Row>
        <Grid.Col xs={12} sm={12} md={12} lg={8}>
          <h3 className="ml-1 mt-2">{event.camp_name}</h3>
        </Grid.Col>
        <Grid.Col xs={12} sm={12} md={12} lg={4}>
          <Grid.Row>
            <Grid.Col xs={6} sm={6} md={8} lg={8}>
              <Button
                block
                color="primary"
                className="mb-3"
                type="submit"
                onClick={handleSubmit}
              >
                UPDATE
              </Button>
            </Grid.Col>
            <Grid.Col xs={6} sm={6} md={4} lg={4}>
              <Button
                block
                outline
                color="secondary"
                className="mb-3"
                onClick={() => setIsEditing("view")}
              >
                DONE
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={12} md={12} lg={4}>
          <Card>
            <Card.Header>
              <Card.Title>Settings</Card.Title>
              <Card.Options></Card.Options>
            </Card.Header>
            <Card.Body>
              {values.status === "Registrations" && (
                <Grid.Row className="mb-3">
                  {event.registration_type === "slots" && (
                    <>
                      <Grid.Col width={12}>
                        <Form.Switch
                          name="student_slots"
                          label="Open Slot Selection"
                          checked={!!values.student_slots}
                          onChange={(e) => {
                            setFieldValue(
                              "student_slots",
                              e.target.checked ? 1 : 0,
                            );
                          }}
                        />
                      </Grid.Col>
                      <Grid.Col sm={12} lg={6}>
                        <div className="h6 mt-3">Slot Duration</div>
                        <Select
                          isClearable={false}
                          isSearchable={false}
                          name="slot_duration"
                          options={slotDurationOptions}
                          onChange={(param) => {
                            setFieldValue("slot_duration", Number(param.value));
                          }}
                          value={
                            values.slot_duration &&
                            slotDurationOptions.find(
                              (option) => option.value === values.slot_duration,
                            )
                          }
                        />
                        <span className="field-error text-danger">
                          {errors.slot_duration &&
                            touched.slot_duration &&
                            errors.slot_duration}
                        </span>
                      </Grid.Col>
                      <Grid.Col sm={12} lg={6}>
                        <div className="h6 mt-3">Participants /slot</div>
                        <FormField
                          name="students_per_slot"
                          placeholder=""
                          type="number"
                          min={1}
                          max={6}
                          value={values.students_per_slot}
                          onChange={handleChange}
                        />
                      </Grid.Col>
                    </>
                  )}
                  {(isAdmin || isCoachSuperAdmin) && (
                    <Grid.Col width={12}>
                      <Form.Switch
                        name="registration_list"
                        label="Confirmed Participants can see Registration List"
                        checked={!!values.registration_list}
                        onChange={(e) => {
                          setFieldValue(
                            "registration_list",
                            e.target.checked ? 1 : 0,
                          );
                        }}
                      />
                    </Grid.Col>
                  )}
                  {![
                    // DO NOT include score cards for these types of events:
                    2, // Competition
                    3, // Boogie
                    5, // event
                  ].includes(Number(event.event_type_id)) && (
                    <Grid.Col width={12}>
                      <Form.Switch
                        name="score_cards"
                        label="Score Cards"
                        checked={!!values.score_cards}
                        onChange={(e) => {
                          setFieldValue(
                            "score_cards",
                            e.target.checked ? 1 : 0,
                          );
                        }}
                      />
                    </Grid.Col>
                  )}
                </Grid.Row>
              )}
              {(isCoachSuperAdmin || isOrg) &&
                event.event_type_id === EEventTypes.competition && (
                  <>
                    <Grid.Row>
                      <Grid.Col>
                        <Form.Group label="Roles">
                          <CampRolesSelect
                            campId={event.id}
                            setCampRoles={setCampRoles}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col>
                        <Form.Group label="Discipline">
                          <CampDisciplineSelect
                            campId={event.id}
                            setCampDisciplines={setCampDisciplines}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                  </>
                )}
            </Card.Body>
          </Card>
        </Grid.Col>
        <Grid.Col xs={12} sm={12} md={12} lg={4}>
          <CalendarEventRequirements event={event} />
        </Grid.Col>
        <Grid.Col xs={12} sm={12} md={12} lg={4}>
          <CalendarEventRegistrations event={event} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={12}>
          <CalendarEventQuestions event={event} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <CalendarItemEditEmail event={event} />
      </Grid.Row>
    </>
  );

  return (
    <Formik
      initialValues={{
        id: event.id,
        registration_list: event.registration_list ? 1 : 0,
        student_slots: event.student_slots ? 1 : 0,
        status: event.status,
        slot_duration: Number(event.slot_duration),
        students_per_slot: Number(event.students_per_slot),
        score_cards: event.score_cards ? 1 : 0,
      }}
      onSubmit={async (values, { setSubmitting, resetForm, setFieldError }) => {
        let isError = false;

        const { ...fields } = values;

        if (
          event.registration_type === "slots" &&
          (values.students_per_slot > 6 || values.students_per_slot < 1)
        ) {
          setFieldError(
            "students_per_slot",
            "Please enter a number between 1-6.",
          );
          isError = true;
        }

        if (isError) {
          setSubmitting(false);
          return;
        }

        await updateCampById({
          variables: {
            camp: {
              ...fields,
            },
          },
        });

        await updateCampRoles({
          variables: {
            campId: event.id,
            roles: campRoles,
          },
        });

        await updateCampDisciplines({
          variables: {
            campId: event.id,
            disciplines: campDisciplines,
          },
        });

        setSubmitting(false);
        resetForm();
        toast.success("Event Updated.");
        setIsEditing("view");
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  );
};

export default CalendarItemEditRegistration;

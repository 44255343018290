import { useMutation } from "@apollo/client";
import {
  ABCDLicensesAPFOptions,
  ABCDLicensesCSPAOptions,
  ABCDLicensesOptions,
} from "components/constants";
import Modal from "components/Modal";
import { Formik } from "formik";
import { ADD_STUDENT_ORG_CREDENTIAL } from "graphql/ADD_STUDENT_ORG_CREDENTIAL";
import { useRootStore } from "hooks";
import { useGetUserIsStudent } from "modules/common/hooks/useGetCurrentUserType";
import moment from "moment";
import React, { useMemo } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Form, Grid } from "tabler-react";
import AddLicenseForm from "../forms/AddLicenseForm";

interface Props {
  orgId: number;
  orgName: string;
  studentId: number;
  toggle?: () => void;
  isOpen: boolean;
}

const AddLicenseModal: React.FC<Props> = ({
  orgId,
  orgName,
  studentId,
  toggle,
  isOpen,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      title="Add License"
      content={
        <AddLicenseForm
          orgId={orgId}
          orgName={orgName}
          studentId={studentId}
          toggle={toggle}
        />
      }
    />
  );
};

export default AddLicenseModal;

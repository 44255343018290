import { useLazyQuery } from "@apollo/client";
import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Icon, Table, Tag, Text } from "tabler-react";
import useReactRouter from "use-react-router";
import { UserStoreContext } from "../../contexts/userStoreContext";
import { GET_STUDENT_BY_ID } from "../../graphql/GET_STUDENT_BY_ID";
import { useGetUserIsAdmin } from "../../modules/common/hooks/useGetCurrentUserType";
import { formatAccountBalance } from "../../utils/formatAccountBalance";
import EditTransactionForm from "./Modal/EditTransactionForm";
import ViewTransactionDetails from "./Modal/ViewTransactionDetails";

const TransactionIcons = {
  cancel: "x",
  used: "minus",
  expiry: "minus",
  purchased: "plus",
  credit: "plus",
  purchase: "credit-card",
  transfer: "arrow-right",
};

interface ITransactionListItemProps {
  transaction: any;
}

const TransactionListItem = ({ transaction }: ITransactionListItemProps) => {
  const { history } = useReactRouter();
  const userStore = useContext(UserStoreContext);
  const { user } = userStore;
  const isAdmin = useGetUserIsAdmin();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [getStudent] = useLazyQuery(GET_STUDENT_BY_ID, {
    onCompleted: (student) => {
      userStore.detach(user);
      userStore.loadUser(student?.getStudentById);
      history.push("/user-details");
    },
  });
  const handleTeamClick = (team_id) => {
    history.push(`/team-details/${team_id}`);
  };

  const handleStudentClick = async (student_id) => {
    await getStudent({
      variables: { student_id: parseInt(student_id) },
      onCompleted: (student) => {
        userStore.detach(user);
        userStore.loadUser(student?.getStudentById);
        history.push("/user-details");
      },
    });
  };

  const handleRedirectToTransactions = async (studentId, teamId) => {
    await getStudent({
      variables: { student_id: parseInt(studentId) },
      onCompleted: (student) => {
        userStore.detach(user);
        userStore.loadUser(student?.getStudentById);
        history.push(`/user-details/${studentId}/student-teams/${teamId}`);
      },
    });
  };

  const amount = useMemo(() => {
    return formatAccountBalance(
      transaction.minutes,
      transaction.accountTypeSlug,
    );
  }, [transaction]);

  return (
    <>
      <EditTransactionForm
        transaction={transaction}
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
      />
      <ViewTransactionDetails
        transaction={transaction}
        isModalOpen={isViewModalOpen}
        toggleModal={setIsViewModalOpen}
        teamId={transaction.teamId}
        studentId={transaction.studentId}
      />
      {isAdmin && (
        <Table.Col>
          <Text RootComponent="span" muted>
            {transaction.id}
          </Text>
        </Table.Col>
      )}
      <Table.Col>
        <Link
          to="#"
          onClick={() =>
            handleRedirectToTransactions(
              transaction.studentId,
              transaction.teamId,
            )
          }
        >
          {transaction.studentFirstName} {transaction.studentLastName}
        </Link>
      </Table.Col>
      <Table.Col>
        <Tag>
          <Icon
            className={`float-left mr-3 mt-3 ${
              transaction.transactionSlug === "used"
                ? "text-secondary"
                : transaction.transactionSlug === "purchased"
                ? "text-success"
                : transaction.transactionSlug === "purchase"
                ? "text-success"
                : "text-muted"
            }`}
            name={TransactionIcons[transaction.transactionSlug]}
          />
          {transaction.transactionTypeName}
        </Tag>
      </Table.Col>
      <Table.Col>{new Date(transaction.created_on).toLocaleString()}</Table.Col>
      {isAdmin && (
        <Table.Col>
          <Link to="#" onClick={() => handleTeamClick(transaction.teamId)}>
            {transaction.teamName}
          </Link>
        </Table.Col>
      )}
      <Table.Col>
        {transaction.coachFirstName} {transaction.coachLastName}
      </Table.Col>
      <Table.Col>
        {transaction.timeAdded ? (
          <Text.Small
            muted={transaction.transactionSlug === "cancel"}
            color="success"
          >
            +{amount}
          </Text.Small>
        ) : (
          <Text.Small
            muted={transaction.transactionSlug === "cancel"}
            color="danger"
          >
            -{amount}
          </Text.Small>
        )}
      </Table.Col>
      <Table.Col>{transaction.notes}</Table.Col>
      <Table.Col>
        <Dropdown
          arrow
          arrowPosition="right"
          triggerContent={<Icon name={"more-vertical"} />}
          position="bottom-end"
          toggle={false}
          className="cursor-pointer"
          itemsObject={[
            { value: "Edit", onClick: () => setIsModalOpen(true) },
            { value: "View", onClick: () => setIsViewModalOpen(true) },
            {
              value: "Student",
              onClick: () => handleStudentClick(transaction.studentId),
            },
          ]}
        />
      </Table.Col>
    </>
  );
};

export default TransactionListItem;

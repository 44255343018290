import { useQuery } from "@apollo/client";
import CalendarLink from "components/Calendar/CalendarLink";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Avatar, Card, Dimmer, Grid, Icon, Table, Text } from "tabler-react";
import useReactRouter from "use-react-router";
import { GET_CAMP_BY_ID } from "../../../graphql/GET_CAMP_BY_ID";
import { GET_CAMP_BY_ID as GET_CAMP_BY_ID_TYPE } from "../../../graphql/types/GET_CAMP_BY_ID";
import { formatMoney } from "../../../utils/numberFormat";
import { calcTotalPaid } from "../../payment/utils/calcTotalPaid";
import useGetRegisteredStudents from "../../user/registrations/hooks/useGetRegisteredStudents";
import { calcTotalFees } from "../../user/registrations/utils/calcTotalFees";

const EventFinance = ({ eventId }) => {
  const { history } = useReactRouter();
  const { loading, data } = useQuery<GET_CAMP_BY_ID_TYPE>(GET_CAMP_BY_ID, {
    variables: {
      campId: Number(eventId),
    },
  });

  const { data: registrationData, loading: registrationLoading } =
    useGetRegisteredStudents({ campId: eventId });

  const participants = useMemo(() => {
    if (!registrationData?.getRegisteredStudents) return [];

    return registrationData?.getRegisteredStudents.filter(
      (student) => student.status === "Confirmed",
    );
  }, [registrationData]);

  return (
    <Dimmer
      loader={loading || registrationLoading}
      active={loading || registrationLoading}
    >
      <Card>
        <Card.Header>
          <Card.Title>
            <strong
              className={"cursor-pointer"}
              onClick={() =>
                history.push(`/event/registration-list/${eventId}`)
              }
            >
              <CalendarLink
                eventName={data?.getCampById?.camp_name}
                eventId={Number(eventId)}
              />
            </strong>
            {} // Finance Report
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader></Table.ColHeader>
                    <Table.ColHeader>Name</Table.ColHeader>
                    <Table.ColHeader>Team</Table.ColHeader>
                    <Table.ColHeader>Discipline</Table.ColHeader>
                    <Table.ColHeader>Total</Table.ColHeader>
                    <Table.ColHeader>Paid</Table.ColHeader>
                    <Table.ColHeader>Balance</Table.ColHeader>
                    <Table.ColHeader></Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {participants.map((student) => (
                    <EventFinanceItem
                      student={student}
                      camp={data.getCampById}
                    />
                  ))}
                </Table.Body>
              </Table>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </Dimmer>
  );
};

export default EventFinance;

const EventFinanceItem = ({ student, camp }) => {
  {
    const { history } = useReactRouter();
    const remainingBalance = useMemo(() => {
      const balance =
        calcTotalFees(student) -
        calcTotalPaid(
          student.payments.map(({ amount, status }) => ({
            amount,
            status,
          })),
        );
      return balance > 0 ? balance : 0;
    }, [student]);

    const compTotal = useMemo(() => {
      return student.payments.reduce((acc, payment) => {
        if (payment.status === "Comp") {
          return acc + payment.amount;
        }
        return acc;
      }, 0);
    }, [student.payments]);

    const totalFees = useMemo(() => {
      return calcTotalFees(student);
    }, [student]);

    let campGroupName = "";
    if (student.campGroups && student.campGroups.length > 0)
      campGroupName = student.campGroups[0].name;
    return (
      <Table.Row key={student.id}>
        <Table.Col>
          <Avatar
            size="md"
            className="cursor-pointer"
            status={student.status_color ?? "danger"}
            imageURL={
              student?.profile_avatar
                ? `/api/s3/uploads/${student.profile_avatar}`
                : "//www.gravatar.com/avatar?d=mp"
            }
            onClick={() =>
              history.push(
                `/events/registrations/${student.user_registrations_id}/${student.id}`,
              )
            }
          />
        </Table.Col>
        <Table.Col>
          <strong>
            <Text
              className="cursor-pointer d-block"
              onClick={() =>
                history.push(
                  `/events/registrations/${student.user_registrations_id}/${student.id}`,
                )
              }
            >
              {student.first_name + " " + student.last_name}
            </Text>
          </strong>
        </Table.Col>
        <Table.Col>{campGroupName}</Table.Col>
        <Table.Col>{student.discipline}</Table.Col>
        <Table.Col>
          {formatMoney(totalFees, camp.registration_currency)}
        </Table.Col>
        <Table.Col>
          {formatMoney(
            totalFees - remainingBalance,
            camp.registration_currency,
          )}
        </Table.Col>
        <Table.Col>
          {formatMoney(remainingBalance, camp.registration_currency)}
        </Table.Col>
        <Table.Col>
          <Icon name="more-vertical" />
        </Table.Col>
      </Table.Row>
    );
  }
};

import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { toast } from "react-toastify";
import { Button, Dimmer } from "tabler-react";
import { ADD_STUDENT_TO_TEAM } from "../../../../graphql/ADD_STUDENT_TO_TEAM";
import { GET_STUDENT_TEAM } from "../../../../graphql/GET_STUDENT_TEAM";
import { REMOVE_STUDENT_FROM_TEAM } from "../../../../graphql/REMOVE_STUDENT_FROM_TEAM";

type CheckInOutButtonProps = {
  studentId: number;
  teamId: number;
};

const CheckInOutButton = ({ studentId, teamId }: CheckInOutButtonProps) => {
  const teamIdValue = Number(teamId);
  const { data, loading } = useQuery(GET_STUDENT_TEAM, {
    variables: {
      student_id: studentId,
      team_id: teamIdValue,
    },
  });
  const [addStudentToTeam] = useMutation(ADD_STUDENT_TO_TEAM, {
    onCompleted: () => {
      toast.success("Checked in to team!");
    },
    refetchQueries: ["GET_STUDENT_TEAM"],
  });
  const [removeStudentFromTeam] = useMutation(REMOVE_STUDENT_FROM_TEAM, {
    onCompleted: () => {
      toast.success("Checked out of team!");
    },
    refetchQueries: ["GET_STUDENT_TEAM"],
  });

  const handleToggleCheck = () => {
    if (data?.getStudentTeam) {
      removeStudentFromTeam({
        variables: {
          studentId,
          teamId: teamIdValue,
        },
      });
    } else {
      addStudentToTeam({
        variables: {
          student_id: studentId,
          team_id: teamIdValue,
        },
      });
    }
  };
  return (
    <Dimmer loading={loading} active={loading}>
      <Button
        block
        color="primary"
        className="d-flex justify-content-center mt-5 mb-5"
        onClick={handleToggleCheck}
      >
        Check
        {data?.getStudentTeam && "In"}
        {!data?.getStudentTeam && "Out"}
      </Button>
    </Dimmer>
  );
};

export default CheckInOutButton;

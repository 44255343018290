import Wrapper from "pages/layout";
import React from "react";
import { useParams } from "react-router";
import EventFinance from "../../modules/camp/components/EventFinance";

const EventFinancePage = (props) => {
  const { eventId } = useParams<{ eventId: string }>();
  return (
    <Wrapper {...props} title="Event Finance">
      <div className="container">
        <EventFinance eventId={eventId} />
      </div>
    </Wrapper>
  );
};

export default EventFinancePage;
